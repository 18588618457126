import React from 'react'
import './projects.scss'
import { projects } from '../../data'

const Projects = ({ title, id }) => {
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      {projects.map(
        ({
          name,
          description,
          website,
          id,
          technologies,
          stillMaintaining,
        }) => {
          return (
            <div key={`${name}${id}`} className="projects">
              <div className="projects__details">
                <div className="projects__name">
                  <a href={website}>{name}</a>
                </div>
                <div className="projects__maintaining">{stillMaintaining}</div>
                <div className="projects__technologies">
                  <h3>Technologies:</h3>
                  <ul className="projects__technologies-list">
                    {technologies.map((tech) => (
                      <li key={tech}>{tech}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className="projects__description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          )
        }
      )}
    </div>
  )
}

export default Projects
