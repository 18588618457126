import React from 'react'
import { interests } from '../../data'
import './interests.scss'

const Interests = ({ title, id }) => {
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      <div className="interests">
        {interests.map((interest) => (
          <span className="interest-item" key={interest}>
            {interest}
          </span>
        ))}
      </div>
    </div>
  )
}

export default Interests
