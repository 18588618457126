import './index.scss'
import placeHolder from './placeholder.png'

const Book = ({ book, getSeries = true }) => {
  // const bookLink = book.link ? <a href={book.link}>{book.title}</a> : book.title

  const getImage = (ISBN) => {
    if (!getSeries) {
      return <li className="no-cover">{book.title}</li>
    }

    const image = book.ISBN ? `https://covers.openlibrary.org/b/isbn/${book.ISBN}-M.jpg` : placeHolder
    return (
      <div className="image-container">
        <p>{book.title}</p>
        <img
          className="image-book"
          title={`${book.title} by ${book.author}`}
          src={image}
          alt={`book cover for ${book.title}`}
        />
      </div>
    )
  }

  return getImage(book.ISBN)
}

export default Book
