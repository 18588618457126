import React, { useRef, useState } from 'react'
import '../async-group.scss'
import AnimItem from '../async-item'

const AsyncPromiseRaceGroup = () => {
  const [firstAnimsResult, setFirstAnimsResult] = useState('Not Started')

  const [anim1Status, setAnim1Status] = useState('Not Started')
  const [anim2Status, setAnim2Status] = useState('Not Started')
  const [anim3Status, setAnim3Status] = useState('Not Started')

  const anim1 = useRef()
  const anim2 = useRef()
  const anim3 = useRef()
  const button = useRef()

  const getStatusColor = (status) => {
    switch (status) {
      case 'Not Started':
        return 'white'
      case 'Pending':
        return 'yellow'
      case 'Fulfilled':
      case 'Animation 1 finished first':
        return 'green'
      case 'Rejected':
        return 'red'
      default:
        return 'white'
    }
  }

  const resetPromisesRaceAnim = () => {
    setFirstAnimsResult('Not Started')
    setAnim1Status('Not Started')
    setAnim2Status('Not Started')
    setAnim3Status('Not Started')
    anim1.current.classList.remove('ani-async-1s')
    anim2.current.classList.remove('ani-async-2s')
    anim3.current.classList.remove('ani-async-3s')
  }

  const startPromiseRaceAnim = async () => {
    setFirstAnimsResult('Pending')
    setAnim1Status('Pending')
    setAnim2Status('Pending')
    setAnim3Status('Pending')

    anim1.current.classList.add('ani-async-1s')
    anim2.current.classList.add('ani-async-2s')
    anim3.current.classList.add('ani-async-3s')

    anim1.current.onanimationend = () => {
      setAnim1Status('Fulfilled')
    }

    anim2.current.onanimationend = () => {
      setAnim2Status('Fulfilled')
    }

    anim3.current.onanimationend = () => {
      setAnim3Status('Fulfilled')
    }

    const animationsPromises = [
      anim1.current?.getAnimations()?.[0]?.finished.then(() => ({
        message: 'Animation 1 finished first',
      })),
      anim2.current?.getAnimations()?.[0]?.finished.then(() => ({
        message: 'Animation 2 finished first',
      })),
      anim3.current?.getAnimations()?.[0]?.finished.then(() => ({
        message: 'Animation 3 finished first',
      })),
    ].filter(Boolean)

    const firstSettledPromise = await Promise.race(
      animationsPromises.map(({ promise }) => promise)
    )

    const firstSettledMessage = await Promise.race(animationsPromises)

    setFirstAnimsResult(firstSettledMessage.message)
  }

  return (
    <React.Fragment>
      <h3>Promise.race - First to settle - Fulfilled</h3>
      <p>Wait for the first animation to finish</p>
      <p>
        With Promise.race the result of the first settled promise in the array
        becomes the result of the Promise.race
      </p>
      <button
        className="animation-ui-button"
        onClick={
          firstAnimsResult === 'Not Started'
            ? startPromiseRaceAnim
            : resetPromisesRaceAnim
        }
      >
        {firstAnimsResult === 'Not Started' ? 'Start' : 'Reset'}
      </button>
      <div className="example">
        <div className="test">
          <AnimItem
            backgroundColor={getStatusColor(anim1Status)}
            ref={anim1}
            status={anim1Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim2Status)}
            ref={anim2}
            status={anim2Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim3Status)}
            ref={anim3}
            status={anim3Status}
          />
        </div>
        <div
          style={{ backgroundColor: getStatusColor(firstAnimsResult) }}
          className="result"
        >
          <div className="result-text">
            <span>Promise.race Result:</span>
            <span>{firstAnimsResult}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AsyncPromiseRaceGroup
