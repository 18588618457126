const Introduction = ({ title, id, smallFont = false }) => {
  const style = smallFont ? {} : { fontSize: '1.5em' }
  return (
    <div className="section" id={id}>
      <h2 className="section__title">{title}</h2>
      <div className="interests"></div>
      <div style={style}>
        <p>
          Accomplished accountant holding qualifications as a Fellow Chartered Certified Accountant (FCCA), Fellow Member of the Association of Accounting Technicians (FMAAT), and Accredited Bookkeeper (AATQB). Equipped with a wealth of experience spanning various company sizes and diverse duties within the realm of accountancy practice. Proficient in leveraging a comprehensive skill set to navigate complex financial landscapes and deliver tailored solutions to meet client needs. Known for meticulous attention to detail, adherence to regulatory standards, and a track record of success in managing multifaceted accounting tasks. Committed to continuous professional development and staying abreast of industry best practices to drive optimal outcomes.
        </p>
        <p>Let’s talk!</p>
        <p>Dane</p>
      </div>
    </div>
  )
}

export default Introduction
