const fiction = [
  {
    title: 'Dresden Files',
    author: 'Jim Butcher',
    type: 'fiction',
    collection: 'series',
    books: [
      {
        title: 'Storm Front',
        ISBN: '0451457811',
      },
      {
        title: 'Fool Moon',
        ISBN: '978-1-4805-9690-0',
      },
      {
        title: 'Grave Peril',
        ISBN: '9780451458445',
      },
      {
        title: 'Summer Knight',
        ISBN: '0451458923',
      },
      {
        title: 'Death Masks',
        ISBN: '0451459407',
      },
      {
        title: 'Blood Rites',
        ISBN: '9780451459879',
      },
      {
        title: 'Dead Beat',
        ISBN: '0451460278',
      },
      {
        title: 'Proven Guilty',
        ISBN: '0451460855',
      },
      {
        title: 'White Night',
        ISBN: '9780451461407',
      },
      {
        title: 'Small Favor',
        ISBN: '9780451461896',
      },
      {
        title: 'Turn Coat',
        ISBN: '9780451462565',
      },
      {
        title: 'Changes',
        ISBN: '9780451463173',
      },
      {
        title: 'Ghost Story',
        ISBN: '9781841497167',
      },
      {
        title: 'Cold Days',
        ISBN: '9780356500959',
      },
      {
        title: 'Skin Game',
        ISBN: '9780451470041',
      },
      {
        title: 'Peace Talks',
        ISBN: '9780356515298',
      },
      {
        title: 'Battle Ground',
        ISBN: '9780593199305',
      },
    ],
  },
  {
    title: 'Discworld 1 - 15',
    author: 'Terry Pratchett',
    collection: 'series',
    type: 'fiction',
    books: [
      { title: 'The Colour of Magic' },
      { title: 'The Light Fantastic' },
      { title: 'Equal Rites' },
      { title: 'Mort' },
      { title: 'Sourcery' },
      { title: 'Wyrd Sisters' },
      { title: 'Pyramids' },
      { title: 'Guards! Guards!' },
      { title: 'Eric' },
      { title: 'Moving Pictures' },
      { title: 'Reaper Man' },
      { title: 'Witches Abroad' },
      { title: 'Small Gods' },
      { title: 'Lords and Ladies' },
      { title: 'Men at Arms' },
    ],
  },
  {
    title: 'Discworld 16 - 30',
    author: 'Terry Pratchett',
    collection: 'series',
    type: 'fiction',
    books: [
      { title: 'Soul Music' },
      { title: 'Interesting Times' },
      { title: 'Maskerade' },
      { title: 'Feet of Clay' },
      { title: 'Hogfather' },
      { title: 'Jingo' },
      { title: 'The Last Continent' },
      { title: 'Carpe Jugulum' },
      { title: 'The Fifth Elephant' },
      { title: 'The Truth' },
      { title: 'Thief of Time' },
      { title: 'The Last Hero' },
      { title: 'The Amazing Maurice and his Educated Rodents' },
      { title: 'Night Watch' },
      { title: 'The Wee Free Men' },
    ],
  },
  {
    title: 'Discworld 31 - 41',
    author: 'Terry Pratchett',
    collection: 'series',
    type: 'fiction',
    books: [
      { title: 'Monstrous Regiment' },
      { title: 'A Hat Full of Sky' },
      { title: 'Going Postal' },
      { title: 'Thud!' },
      { title: 'Wintersmith' },
      { title: 'Making Money' },
      { title: 'Unseen Academicals' },
      { title: 'I Shall Wear Midnight' },
      { title: 'Snuff' },
      { title: 'Raising Steam' },
      { title: 'The Shepherd’s Crown' },
    ],
  },
  {
    title: 'Wheel of Time',
    author: 'Robert Jordan/Brandon Sanderson',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'The Eye of the World (The Wheel of Time book 1)' },
      { title: 'The Great Hunt (The Wheel of Time book 2)' },
      { title: 'The Dragon Reborn (The Wheel of Time book 3)' },
      { title: 'The Shadow Rising (The Wheel of Time book 4)' },
      { title: 'The Fires of Heaven (The Wheel of Time book 5)' },
      { title: 'Lord of Chaos (The Wheel of Time book 6)' },
      { title: 'A Crown of Swords (The Wheel of Time book 7)' },
      { title: 'The Path of Daggers (The Wheel of Time book 8)' },
      { title: 'Winter’s Heart (The Wheel of Time book 9)' },
      { title: 'Crossroads of Twilight (The Wheel of Time book 10)' },
      { title: 'Knife of Dreams (The Wheel of Time book 11)' },
      { title: 'The Gathering Storm (The Wheel of Time book 12)' },
      { title: 'Towers of Midnight (The Wheel of Time book 13)' },
      { title: 'A Memory of Light (The Wheel of Time book 14)' },
      { title: 'New Spring (The Wheel of Time book 15)' },
    ],
  },
  {
    title: 'Foundation',
    author: 'Isaac Asimov',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'I, Robot' },
      { title: 'The Caves of Steel' },
      { title: 'The Naked Sun' },
      { title: 'The Robots of Dawn' },
      { title: 'Robots and Empire' },
      { title: 'The Stars, Like Dust' },
      { title: 'The Currents of Space' },
      { title: 'Pebble in the Sky' },
    ],
  },

  {
    title: 'Dragonlance Chronicles',
    author: 'Margaret Weis, Tracy Hickman',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Chronicles 01 - Dragons of Autumn Twilight' },
      { title: 'Chronicles 02 - Dragons of Winter Night' },
      { title: 'Chronicles 03 - Dragons of Spring Dawning' },
      { title: 'Legends 01 - Time of the Twins' },
      { title: 'Legends 02 - War of the Twins' },
      { title: 'Legends 03 - Test of the Twins' },
      { title: 'Tales 01 - The Magic of Krynn' },
      { title: 'Tales 02 - Kender, Gully Dwarves and Gnomes' },
    ],
  },
  {
    title: 'Dune Series',
    author: 'Frank Herbert',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Dune (1965)' },
      { title: 'Dune Messiah (1969)' },
      { title: 'Children of Dune (1976)' },
      { title: 'God Emperor of Dune (1981)' },
      { title: 'Heretics of Dune (1984)' },
      { title: 'Chapterhouse: Dune (1985)' },
      { title: 'Dune: House Atreides (1999)' },
      { title: 'Dune: House Harkonnen (2000)' },
    ],
  },
  {
    title: 'Saga of the 7 Suns',
    author: 'Kevin J Anderson',
    collection: 'series',
    type: 'fiction',
    books: [
      { title: 'Veiled Alliances (2011)' },
      { title: 'Hidden Empire (2002)' },
      { title: 'A Forest of Stars (2003)' },
      { title: 'Horizon Storms (2004)' },
      { title: 'Scattered Suns (2005)' },
      { title: 'Of Fire and Night (2006)' },
      { title: 'Metal Swarm (2007)' },
      { title: 'The Ashes of Worlds (2008)' },
    ],
  },
  {
    title: 'Mistborn',
    author: 'Brandon Sanderson',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Mistborn: The Final Empire	(2006)' },
      { title: 'The Well of Ascension	(2007)' },
      { title: 'The Hero of Ages	(2008)' },
      { title: 'The Alloy of Law	(2011)' },
      { title: 'Shadows of Self	(2015)' },
      { title: 'The Bands of Mourning	(2016)' },
      { title: 'The Lost Metal	(2022)' },
    ],
  },
  {
    title: 'Iron Druid Chronicles',
    author: 'Kevin Hearne',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: '1. Hounded (May 2011)' },
      { title: '2. Hexed (June 2011)' },
      { title: '3. Hammered (July 2011)' },
      { title: '4. Tricked (April 2012)' },
      { title: '5. Trapped (November 2012)' },
      { title: '6. Hunted (June 2013)' },
      { title: '7. Shattered (June 2014)' },
      { title: '8. Staked (January 26, 2016)' },
      { title: '8.5 Besieged (July 11, 2017) - On reading list' },
      { title: '9. Scourged (April 5, 2018) - On reading list' },
    ],
  },
  {
    title: 'Codex Alera',
    author: 'Jim Butcher',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Furies of Calderon' },
      { title: "Academ's Fury" },
      { title: "Cursor's Fury" },
      { title: "Captain's Fury" },
      { title: "Princep's Fury" },
      { title: "First Lord's Fury" },
    ],
  },
  {
    title: 'Hitchikers Guide to the Galaxy',
    author: 'Douglas Adams',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: "The Hitchhiker's Guide to the Galaxy" },
      { title: 'The Restaurant at the End of the Universe' },
      { title: 'Life, the Universe and Everything' },
      { title: 'So Long, and Thanks for All the Fish' },
      { title: 'Mostly Harmless' },
      { title: 'And Another Thing - On Reading List (by Eion Colfer)' },
    ],
  },
  {
    title: 'Wanderer',
    author: 'Simon Goodsen',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: "Wanderer's Escape" },
      { title: 'Wanderer: Echoes of the Past' },
      { title: 'Tainted Universe' },
      { title: 'Wanderer: Origins' },
      { title: 'Wanderer: Extinction' },
      { title: 'Wanderer: Salvation' },
    ],
  },

  {
    title: 'Landover',
    author: 'Terry Brooks',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Magic Kingdom For Sale-Sold!' },
      { title: 'The Black Unicorn.' },
      { title: 'Wizard at Large.' },
      { title: 'The Tangle Box - On Reading list' },
      { title: "Witches' Brew - On Reading list" },
      { title: 'A Princess of Landover - On Reading list' },
    ],
  },
  {
    title: 'Enders Game',
    author: 'Orson Scott Card',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: "Ender's Game" },
      { title: 'Ender in Exile' },
      { title: 'Speaker for the Dead' },
      { title: 'Xenocide' },
      { title: 'Children of the Mind' },
    ],
  },
  {
    title: 'The Stormlight Archive',
    author: 'Brandon Sanderson',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'The Way of Kings' },
      { title: 'Words of Radiance' },
      { title: 'Oathbringer' },
      { title: 'Rythm of War - On reading list' },
    ],
  },

  {
    title: 'The Long Earth',
    author: 'Terry Pratchett',
    collection: 'series',
    type: 'fiction',
    books: [
      { title: 'The Long War (June 2013)' },
      { title: 'The Long Mars (June 2014)' },
      { title: 'The Long Utopia (June 2015)' },
      { title: 'The Long Cosmos (June 2016) - On reading list' },
    ],
  },

  {
    title: 'Hyperion Cantos',
    author: 'Dan Simmons',
    collection: 'series',
    type: 'fiction',
    books: [
      {
        title: 'Hyperion',
      },
      {
        title: 'The Fall of Hyperion',
      },
      {
        title: 'Endymion',
      },
      {
        title: 'The Rise of Endymion',
      },
    ],
  },
  {
    title: 'Saga of the Exiles',
    author: 'Julian May',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'The Many-Coloured Land' },
      { title: 'The Golden Torc' },
      { title: 'The Nonborn King' },
      { title: 'The Adversary' },
    ],
  },
  {
    title: 'Revelation Space',
    author: 'Alistair Reynolds',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Revelation Space', ISBN: '0441009425' },
      { title: 'Redemption Ark', ISBN: '9780316462495' },
      { title: 'Absolution Gap', ISBN: '9780316462631' },
      { title: 'Inhibitor Phase', ISBN: '9780316462761' },
    ],
  },
  {
    title: 'Galactic Milieu Trilogy of the Exiles',
    author: 'Julian May',
    collection: 'series',
    type: 'fiction',
    books: [
      { title: 'Jack the Bodiless ' },
      { title: 'Magnificat' },
      { title: 'Diamond Mask' },
    ],
  },

  {
    collection: 'series',
    title: 'The Nights Dawn Trilogy',
    author: 'Peter F Hamilton',
    type: 'fiction',
    books: [
      { title: 'The Reality Disfunction' },
      { title: 'The Nuetronium Alchemist' },
      { title: 'The Naked God' },
    ],
  },

  {
    title: 'Greg Mandel Trilogy',
    collection: 'series',
    author: 'Peter F Hamilton',
    type: 'fiction',
    books: [
      {
        title: 'Mindstar Rising',
        ISBN: '9780812590562',
      },
      {
        title: 'Quantum Murder',
        ISBN: '9780330537759',
      },
      {
        title: 'Nano Flower',
        ISBN: '9780330330442',
      },
    ],
  },

  {
    title: 'Night Angel',
    author: 'Brent Weeks',
    collection: 'series',
    type: 'fiction',
    url: 'https://www.amazon.com/gp/product/B001E0V112/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i3',
    books: [
      {
        title: 'Way of Shadows',
        ISBN: '9780316033671',
      },
      {
        title: "Shadow's Edge",
        ISBN: '9781841497419',
      },
      {
        title: 'Beyond the Shadows',
        ISBN: '9781841497426',
      },
    ],
  },
  {
    title: 'The Salvation Sequence',
    author: 'Peter F Hamilton',
    type: 'fiction',
    collection: 'series',
    books: [
      {
        title: 'Salvation',
        ISBN: '9780399178849',
      },
      {
        title: 'Slavation Lost',
        ISBN: '9781515962793',
      },
      {
        title: 'Saints of Salvation',
        ISBN: '9780399178887',
      },
    ],
  },
  {
    title: 'The Common Wealth Saga',
    author: 'Peter F Hamilton',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Misspent Youth' },
      { title: "Pandora's Star" },
      { title: 'Judas Unchained' },
    ],
  },

  {
    title: "Poseiden's Children",
    author: 'Alistair Reynolds',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'Blue Remembered Earth' },
      { title: 'On the Steel Breeze' },
      { title: "Poseiden's Wake" },
    ],
  },

  {
    title: 'Zone of Thought',
    author: 'Vernor Vinge',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'A Fire Upon The Deep' },
      { title: 'A Deepness In The Sky' },
      { title: 'The Children Of The Sky' },
    ],
  },
  {
    title: 'The Three Body Problem',
    author: 'Liu Cixin',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'The Three-Body Problem' },
      { title: 'The Dark Forest' },
      { title: "Death's End" },
    ],
  },
  {
    title: 'Shannara',
    author: 'Terry Brooks',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'The Sword of Shannara (1977)' },
      { title: 'The Elfstones of Shannara (1982)' },
      { title: 'The Wishsong of Shannara (1985)' },
    ],
  },

  {
    title: 'Kingkiller Chronicles',
    author: 'Patrick Rothfuss',
    type: 'fiction',
    collection: 'series',
    books: [
      { title: 'The Name of the Wind' },
      { title: "The Wise Man's Fear" },
    ],
  },
  {
    title: 'Master of the 5 Magics',
    author: 'Lyndon Hardy',
    type: 'fiction',
    collection: 'series',
    books: [
      {
        title: 'Master of the 5 Magics',
        author: 'Lyndon Hardy',
        type: 'fiction',
        ISBN: '',
      },
    ],
  },
  {
    title: 'Mageborn',
    author: 'Jessica Thorne',
    type: 'fiction',
    collection: 'series',
    books: [{ title: 'The Hollow King' }],
  },
]

export default fiction
