import Navbar from '../navbar'
import React from 'react'
import './app-style.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from '../home'
import { connect } from 'react-redux'
import { getUdemyCourses } from '../../actions'
import CVCompleteDB from '../cvDB'

import CVComplete from '../cv-complete'
// import CVForPrint from '../cv-for-print'

class App extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Navbar />
          <Switch>
            <Route path="/cv">
              <CVComplete />
            </Route>
            {/* <Route path="/cv-print">
              <CVForPrint />
            </Route> */}
            <Route path="/cv-db">
              <CVCompleteDB />
            </Route>
            <Route path="/">
              <div style={{ textAlign: 'center' }}>
                <Home />
              </div>
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { courses: state.courses }
}

export default connect(mapStateToProps, { getUdemyCourses })(App)
