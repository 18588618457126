const unsplashRandom = 'https://source.unsplash.com/random'

const courses = [
  {
    id: '1',
    name: 'name 1',
    expiry: false,
    image: unsplashRandom,
    url: 'https:somewhere',
  },
  {
    id: '2',
    name: 'name 2',
    expiry: false,
    image: unsplashRandom,
    url: 'https:somewhere2',
  },
  {
    id: '3',
    name: 'name 3',
    image: unsplashRandom,
    expiry: false,
    url: 'https:somewhere3',
  },
  {
    id: '4',
    name: 'name 4',
    image: unsplashRandom,
    expiry: false,
    url: 'https:somewhere4',
  },
  {
    id: '5',
    image: unsplashRandom,
    name: 'name 5',
    expiry: false,
    url: 'https:somewhere5',
  },
]

const udemyCourses = (state = courses, action) => {
  switch (action.type) {
    case 'GET_UDEMY_COURSES':
      return action.payload
    default:
      return state
  }
}

export default udemyCourses
