import tavcom from './images/tavcom.jpg'
import freeCodeCamp from './images/freecodecamp.png'
import midKentCollege from './images/mid-kent-college.jpeg'

export default [{
  id: '4',
  title: 'IT and Cyber Security',
  description: 'BTEC Level 3 in Cyber Security',
  link: 'https://www.tavcom.com',
  image: tavcom,
  institution: 'Tavcom Training',
  date: '6th February 2017',
},
{
  id: '3',
  title: 'IT Networking',
  description: 'BTEC Level 4 in IT and Networking',
  link: 'https://www.tavcom.com',
  image: tavcom,
  institution: 'Tavcom Training',
  date: 'March 2016',
},
{
  id: '2',
  title: 'Free Code Camp',
  description: 'Front end Certificate',
  link: 'https://www.freecodecamp.org',
  image: freeCodeCamp,
  institution: 'Free Code Camp',
  date: 'February 2016',
},
{
  id: '1',
  title: 'HNC Electronic Engineering',
  description: 'Higher National Certificate in Electronic Engineering',
  link: 'https://www.midkent.ac.uk/course-details/engineering/hnc-in-electronic-engineering/89p541-ap2122/',
  image: midKentCollege,
  institution: 'Mid Kent College, Medway Park',
  date: '2012',
},]