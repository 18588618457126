const podcasts = [
  {
    id: 6,
    name: 'Risky Business',
    url: 'https://risky.biz/feeds/risky-business',
    website: 'https://risky.biz',
    description: 'Security podcast',
  },
  {
    id: 7,
    name: 'The Sceptics Guide to the Universe',
    url: 'https://www.theskepticsguide.org/podcastfeed.aspx',
    website: 'https://www.theskepticsguide.org/',
    description: 'Science podcast',
  },
  {
    id: 2,
    name: 'Javascript Jabber',
    url: 'https://topenddevs.com/podcasts/javascript-jabber',
    description: 'JavaScript podcast',
    website: 'https://topenddevs.com',
  },
  {
    id: 3,
    name: 'React Roundup',
    url: 'https://topenddevs.com/podcasts/react-round-up',
    website: 'https://topenddevs.com',
    description: 'React podcast',
  },
  {
    id: 4,
    name: 'Science Weekly',
    url: 'https://www.theguardian.com/science/series/science',
    website: 'https://www.theguardian.com/science/series/science',
    description: 'Science podcast',
  },
  {
    id: 5,
    name: 'Politics Weekly',
    url: 'https://www.theguardian.com/politics/series/politicsweekly/podcast.xml',
    website: 'https://www.theguardian.com',
    description: 'Politics podcast',
  },
  {
    id: 0,
    name: 'The Infinite Monkey Cage',
    url: 'https://www.bbc.co.uk/programmes/b00snr0w/episodes/downloads.rss',
    description: 'Science podcast',
    website: 'https://www.bbc.co.uk',
  },
  {
    id: 1,
    name: 'The Curious Cases of Rutherford and Fry',
    url: 'https://www.bbc.co.uk/programmes/b00snr0w/episodes/downloads.rss',
    website: 'https://www.bbc.co.uk',
    description: 'Science podcast',
  },
]

export default podcasts
