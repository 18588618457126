import React from 'react'
import './async-item.scss'

const AnimItem = React.forwardRef(({ status, backgroundColor }, ref) => {
  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="anim-container"
    >
      <p>Start ------------- End</p>
      <div ref={ref} className="anim"></div>
      <p>Promise State: {status}</p>
    </div>
  )
})

export default AnimItem
