import React, { useRef, useState, useEffect } from 'react';
import '../async-group.scss';
import AnimItem from '../async-item';

let AggregateError = typeof window.AggregateError !== 'undefined'
  ? window.AggregateError
  : class AggregateError extends Error {
    constructor(errors, message) {
      super(message);
      this.errors = errors;
      this.name = 'AggregateError';
    }
  };

export const AsyncPromiseAnyAggregateFailureGroup = () => {
  const [firstAnimsResult, setFirstAnimsResult] = useState('Not Started');
  const [anim1Status, setAnim1Status] = useState('Not Started')
  const [anim2Status, setAnim2Status] = useState('Not Started')
  const [anim3Status, setAnim3Status] = useState('Not Started')
  const [failureCount, setFailureCount] = useState(0);
  const [timeoutIds, setTimeoutIds] = useState([]);
  const [aggregateError, setAggregateError] = useState(null);

  const anim1 = useRef();
  const anim2 = useRef();
  const anim3 = useRef();

  useEffect(() => {
    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, []);

  const resetPromisesAnyAnim = () => {
    setFirstAnimsResult('Not Started');
    setAnim1Status('Not Started')
    setAnim2Status('Not Started')
    setAnim3Status('Not Started')
    setFailureCount(0);
    setAggregateError(null);
    timeoutIds.forEach(clearTimeout);
    setTimeoutIds([]);
    anim1.current.classList.remove('ani-async-1s')
    anim2.current.classList.remove('ani-async-2s')
    anim3.current.classList.remove('ani-async-3s')
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Not Started': return 'white';
      case 'Pending': return 'yellow';
      case 'Fulfilled': return 'green';
      case 'Rejected': return 'red';
      default: return 'white';
    }
  };

  const startPromiseAnyAnim = async () => {
    setFirstAnimsResult('Pending');
    const allErrors = [];
    setAnim1Status('Pending')
    setAnim2Status('Pending')
    setAnim3Status('Pending')

    // Start the animations
    anim1.current.classList.add('ani-async-1s');
    anim2.current.classList.add('ani-async-2s');
    anim3.current.classList.add('ani-async-3s');

    // Create timeouts to simulate failures at different times
    const newTimeoutIds = [
      setTimeout(() => {
        anim1.current.getAnimations().forEach((animation) => animation.cancel());
        setFailureCount(prevCount => prevCount + 1);
        setAnim1Status('Rejected')
      }, 500),
      setTimeout(() => {
        anim2.current.getAnimations().forEach((animation) => animation.cancel());
        setFailureCount(prevCount => prevCount + 1);
        setAnim2Status('Rejected')
      }, 1500),
      setTimeout(() => {
        anim3.current.getAnimations().forEach((animation) => animation.cancel());
        setFailureCount(prevCount => prevCount + 1);
        setAnim3Status('Rejected')
      }, 2500)
    ];

    setTimeoutIds(newTimeoutIds);

    try {
      const result = await Promise.any([
        ...anim1.current.getAnimations().map((animation) => animation.finished),
        ...anim2.current.getAnimations().map((animation) => animation.finished),
        ...anim3.current.getAnimations().map((animation) => animation.finished)
      ]);
      setFirstAnimsResult('Fulfilled');
    } catch (err) {
      if (err instanceof AggregateError) {

        setFailureCount(err.errors.length);
      }
      const aggregate = new AggregateError(allErrors, 'All Animations Failed');
      setAggregateError(aggregate);
      setFirstAnimsResult('Rejected');
    }
  };
  return (
    <React.Fragment>
      <h3>Promise.any - All Fail</h3>
      <button className="animation-ui-button"
        onClick={
          firstAnimsResult === 'Not Started'
            ? startPromiseAnyAnim
            : resetPromisesAnyAnim
        }
      >
        {firstAnimsResult === 'Not Started' ? 'Start' : 'Reset'}
      </button>
      <div className="example">
        <div className="test">
          <AnimItem
            backgroundColor={getStatusColor(anim1Status)}
            ref={anim1}
            status={anim1Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim2Status)}
            ref={anim2}
            status={anim2Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim3Status)}
            ref={anim3}
            status={anim3Status}
          />
        </div>
        <div className="result" style={{ backgroundColor: getStatusColor(firstAnimsResult), flexDirection: 'column' }}>
          <span>Promise.any Result:</span>
          <span>{firstAnimsResult}</span>
          {aggregateError && (
            <div>
              <h4>Aggregate Error:</h4>
              <p>{aggregateError.message}</p>
              <ul>
                {aggregateError.errors.map((err, index) => (
                  <li key={index}>{err.message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AsyncPromiseAnyAggregateFailureGroup;
