import React from 'react'
import { podcasts } from '../../data'
import './podcasts.scss'

const Podcast = ({ title, id }) => {
  const renderList = podcasts.map(({ id, name, description, website, url }) => {
    return (
      <div key={id} className="podcast">
        <div className="podcast__name">{name}</div>
        {/* <div className="blog-post__heading-company">
              <p>{location}</p>
            </div> */}
        <div className="podcast__website">
          <a href={website}>{website}</a>
        </div>
        <div className="podcast__description">
          <a href={url}>
            <p>{description}</p>
          </a>
        </div>
      </div>
    )
  })
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      {renderList}
    </div>
  )
}

export default Podcast
