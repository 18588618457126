import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

import { socialLinks } from '../../data'
import './social.scss'

const socialLogos = {
  LinkedIn: (
    <FontAwesomeIcon className="social-items__item" icon={faLinkedin} />
  ),
  Twitter: <FontAwesomeIcon className="social-items__item" icon={faTwitter} />,
  GitHub: <FontAwesomeIcon className="social-items__item" icon={faGithub} />,
}

const Social = ({ title, id, linksOnly = false }) => {
  const socialLinkList = socialLinks.map((item, index) => {
    if (linksOnly === true) {
      return (
        <a className="social-items__item" href={item.url}>
          {socialLogos[item.name]}
          <p>{item.name}: {item.url}</p>
        </a>
      )
    }
    return (
      <div key={title + index} className="social-items">
        <a className="social-items__item" href={item.url}>
          {socialLogos[item.name]}
          <p>{item.name}</p>
        </a>
        {/* <a className="social-items__item" href={item.url}>
          {item.url}
        </a> */}
      </div>
    )
  })

  return (
    <div>
      <h2 id={id} className="section__title">{title}</h2>
      {socialLinkList}
    </div>
  )
}

export default Social
