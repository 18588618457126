import nonFictionBooks from './non_fiction'
import fictionSeries from './fiction/series'
import fictionSingles from './fiction/singles'

const books = {
  nonFictionBooks,
  fictionSeries,
  fictionSingles,
}

export default books
