import React from 'react'
import './home.scss'
import AsyncPromiseAllSuccessGroup from './async-promise-all-success-group'
import AsyncPromiseOneFailGroup from './async-promise-one-fail-group'
import AsyncPromiseRaceGroup from './async-promise-race-success-group'
import AsyncPromiseRaceOneFailGroup from './async-promise-race-one-fail-group'
import AsyncPromiseAllSettledGroup from './async-promise-all-settled-group'
import GridFlex from '../grid-flex'
import AsyncPromiseAnyGroup from './async-promise-any-group'
import AsyncPromiseAnyAggregateFailureGroup from './async-promise-any-aggregate-failure-group'
import PromiseGroups from './promise-groups'

const Home = () => {
  return (
    <div className="coffee-images-container">
      <h1 class="promise-groups-title">Understanding promises - visually</h1>
      <PromiseGroups>
        <AsyncPromiseAllSuccessGroup />
        <AsyncPromiseOneFailGroup />
        <AsyncPromiseRaceGroup />
        <AsyncPromiseRaceOneFailGroup />
        <AsyncPromiseAllSettledGroup />
        <AsyncPromiseAnyGroup />
        <AsyncPromiseAnyAggregateFailureGroup />
      </PromiseGroups>
      <h2>Playing with Grid and Flex</h2>
      <GridFlex />
    </div>
  )
}

export default Home
