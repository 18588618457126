const blogs = [
  // {
  //   id: '',
  //   location: 'Medium',
  //   name: '',
  //   description: '',
  //   link: '',
  //   datePublished: '',
  // },
  {
    id: '32',
    location: 'Medium',
    name: 'Effortlessly Extracting Commits: Keeping Your Feature Branch Clean',
    description:
      'Extracting mini refactor commits from your feature branch to keep it clean and tidy',
    link: 'https://medium.com/@kristiansigston/effortlessly-extracting-commits-keeping-your-feature-branch-clean-b24ceb4b7bc9',
    datePublished: 'July 2024',
  },
  {
    id: '31',
    location: 'Medium',
    name: 'Future Fitness: Revolutionizing Workouts with AI and Mind Interfaces 🚀',
    description:
      'Automated work outs that you nee not feel or be functionally present for',
    link: 'https://medium.com/@kristiansigston/future-fitness-revolutionizing-workouts-with-ai-and-mind-interfaces-549276ae9ecf',
    datePublished: 'March 2024',
  },
  {
    id: '30',
    location: 'Medium',
    name: 'The Rising Demand for Customer Service in the Digital Era 📈',
    description: 'Bots on phones and websites',
    link: 'https://medium.com/@kristiansigston/revolutionizing-customer-service-in-the-digital-age-the-strategic-role-of-chatbots-e45f6de4e0ee',
    datePublished: 'January 2024',
  },
  {
    id: '29',
    location: 'Medium',
    name: '📚 Rethinking Education: Dispelling Myths and Questioning Tradition 🤔',
    description: 'How do I teach, you teach, we teach, they teach',
    link: 'https://medium.com/@kristiansigston/rethinking-education-dispelling-myths-and-questioning-tradition-1e3762d65bd5',
    datePublished: 'October 2023',
  },
  {
    id: '28',
    location: 'Medium',
    name: 'Can innovation and problem solving only occur with watercooler moments?',
    description: 'Engineering ideas for success',
    link: 'https://medium.com/@kristiansigston/can-innovation-and-problem-solving-only-occur-without-watercooler-moments-317360695d99',
    datePublished: 'October 2023',
  },
  {
    id: '27',
    location: 'Medium',
    name: 'Unlocking the Power of JavaScript Promises 🔓: A Deep Dive into Promise.all(),',
    description: 'JavaScript Promises 🔓: A Deep Dive into Promise.all()',
    link: 'https://kristiansigston.medium.com/unlocking-the-power-of-javascript-promises-a-deep-dive-into-promise-all-3720a726dd67',
    datePublished: 'August 2023',
  },
  {
    id: '26',
    location: 'Medium',
    name: 'From Burnout 🔥 to Balance ⚖️: My Journey of Recovery and Resilience ✨',
    description: 'Burnout to Balance',
    link: 'https://kristiansigston.medium.com/from-burnout-to-balance-%EF%B8%8F-my-journey-of-recovery-and-resilience-814ed3a59834',
    datePublished: 'August 2023',
  },
  {
    id: '25',
    location: 'Medium',
    name: 'Junior vs. Senior Developers: Beyond the Code 🖥️✨',
    description:
      "Transitioning from Junior to Senior Developer. It's not overnight",
    link: 'https://kristiansigston.medium.com/junior-vs-senior-developers-beyond-the-code-%EF%B8%8F-eb0fb3a65b6c',
    datePublished: 'August 2023',
  },
  {
    id: '24',
    location: 'Medium',
    name: 'Developer Attrition: Leaving for Better Roles 🚀',
    description: 'Why do developers leave?',
    link: 'https://kristiansigston.medium.com/developer-attrition-leaving-for-better-roles-56df3314853f',
    datePublished: 'August 2023',
  },
  {
    id: '23',
    location: 'Medium',
    name: 'The Intricacies of Software Estimation 🧠',
    description: 'How difficult it is to estimate software production times',
    link: 'https://kristiansigston.medium.com/the-intricacies-of-software-estimation-51cb46618480',
    datePublished: 'August 2023',
  },
  {
    id: '22',
    location: 'Medium',
    name: 'Rethinking Success: What Is Thought To Matter to us all in the Modern Age?🌐',
    description: 'A discussion on what success can mean today',
    link: 'https://kristiansigston.medium.com/rethinking-success-what-is-thought-to-matter-to-us-all-in-the-modern-age-5035c0c1ffd3',
    datePublished: 'August 2023',
  },
  {
    id: '21',
    location: 'Medium',
    name: '🌟 Considering the NHS shortfall: A Technological Revolution and Collaborative Approach 🌟',
    description: 'Can we use technology to help the NHS?',
    link: 'https://kristiansigston.medium.com/considering-the-nhs-shortfall-a-technological-revolution-and-collaborative-approach-7eba3ca99287',
    datePublished: 'August 2023',
  },
  {
    id: '20',
    location: 'Medium',
    name: '🌟 Revolutionizing ICU Nursing with Apple Vision Pro and other AR devices: A Futuristic Approach 🌟',
    description: 'AR Vision Pro or AR in Hospital ICUs/Wards',
    link: 'https://kristiansigston.medium.com/revolutionizing-icu-nursing-with-apple-vision-pro-and-other-ar-devices-a-futuristic-approach-e6f4b40e1eba',
    datePublished: 'July 2023',
  },
  {
    id: '19',
    location: 'Medium',
    name: '🚀 More than Monarchs: The Extraordinary Ways In Which Our Lives Now Seem to Surpass Those of Yesteryear’s Royalty 🌐',
    description:
      'The Extraordinary Ways In Which Our Lives Now Seem to Surpass Those of Yesteryear’s Royalty',
    link: 'https://medium.com/@kristiansigston/more-than-monarchs-the-extraordinary-ways-in-which-our-lives-now-seem-to-surpass-those-of-7b211db70103',
    datePublished: 'June 2023',
  },
  {
    id: '18',
    location: 'Medium',
    name: 'The Essential Role of Exercise: Smarter Approaches for the Desk-Bound 💼🏋️‍♀️',
    description: 'Exercising smartly',
    link: 'https://medium.com/@kristiansigston/wortitle-the-essential-role-of-exercise-smarter-approaches-for-the-desk-bound-%EF%B8%8F-%EF%B8%8F-2b90093b205f',
    datePublished: 'June 2023',
  },
  {
    id: '17',
    location: 'Medium',
    name: 'The Future of AI-Web Interactions: A Brave New Frontier 🚀',
    description: 'AI web interactions. Sites for bots.',
    link: 'https://medium.com/@kristiansigston/the-future-of-ai-web-interactions-a-brave-new-frontier-93e826734a50',
    datePublished: 'June 2023',
  },
  {
    id: '16',
    location: 'Medium',
    name: '🚀 Unleashing AI in the Shopping Realm: A New Era of Retail 🛍️',
    description: 'AI, AR in the Shopping Realm',
    link: 'https://medium.com/@kristiansigston/title-unleashing-ai-in-the-shopping-realm-a-new-era-of-retail-%EF%B8%8F-87329aa02567',
    datePublished: 'June 2023',
  },
  {
    id: '15',
    location: 'Medium',
    name: 'The Benefits of Balance: Embracing the New Normal of Remote Work 🏠',
    description: 'Hyyrid working, remote working or office working.',
    link: 'https://medium.com/@kristiansigston/the-benefits-of-balance-embracing-the-new-normal-of-remote-work-f8f0b3506f3a',
    datePublished: 'May 2023',
  },
  {
    id: '14',
    location: 'Medium',
    name: 'How Can I Begin to Harness the Power of ChatGPT: A Considered Guide',
    description: 'ChatGPT overview',
    link: 'https://medium.com/@kristiansigston/how-can-i-begin-to-harness-the-power-of-chatgpt-a-considered-guide-1fc4e0d601b0',
    datePublished: 'April 2023',
  },
  {
    id: '13',
    location: 'Medium',
    name: 'Self Driving Transport',
    description: 'Self Driving Cars',
    link: 'https://medium.com/@kristiansigston/self-driving-cars-409564f1e0f6',
    datePublished: 'March 2023',
  },
  {
    id: '12',
    location: 'Medium',
    name: 'ChatGPT Revolutionising task flows',
    description: 'Speeding us up',
    link: 'https://medium.com/@kristiansigston/title-how-gpt-is-revolutionizing-language-related-tasks-and-workflows-fabd242a2d63',
    datePublished: 'February 2023',
  },
  {
    id: '11',
    location: 'Medium',
    name: 'Back to Basics with Company Reward Schemes',
    description: 'What will really motivate our employees',
    link: 'https://medium.com/@kristiansigston/back-to-basics-with-company-reward-schemes-42661ec6741c',
    datePublished: 'February 2023',
  },
  {
    id: '10',
    location: 'Medium',
    name: 'Help. People are saying I am slow developer. How can I be faster?',
    description: 'Slow and Fast',
    link: 'https://kristiansigston.medium.com/help-i-am-slow-developer-how-can-i-be-faster-91d79618a065',
    datePublished: 'February 2023',
  },

  {
    id: '9',
    location: 'Medium',
    name: 'Online CVs for Appraisals and new Roles',
    description: 'How to improve representation and exposure',
    link: 'https://kristiansigston.medium.com/how-can-i-do-better-at-yearly-appraisals-or-get-more-engagement-from-recruiters-cd683f30e3cb',
    datePublished: 'December 2022',
  },
  {
    id: '8',
    location: 'Medium',
    name: 'The office, home and hybrid oh my!',
    description: 'Hybrid',
    link: 'https://kristiansigston.medium.com/the-office-home-and-hybrid-oh-my-c96071ef4e3',
    datePublished: 'October 2022',
  },
  {
    id: '7.5',
    location: 'Medium',
    name: 'Side projects and studying',
    description: 'Side projects and studying and perosnal growth',
    link: 'https://medium.com/@kristiansigston/side-projects-and-studying-7960c15bd821',
    datePublished: 'October 2022',
  },
  {
    id: '7',
    location: 'Medium',
    name: 'Samus and back to the Super Metroid',
    description: 'Super Metroid game',
    link: 'https://kristiansigston.medium.com/samus-and-back-to-the-super-metroid-12355700447e',
    datePublished: 'August 2022',
  },
  {
    id: '6',
    location: 'Medium',
    name: 'Software roles availibility',
    description: 'Are there going to be less software jobs in the future',
    link: 'https://kristiansigston.medium.com/software-and-tech-roles-high-wages-to-come-back-down-in-5-years-2758f9c2d399',
    datePublished: 'August 2022',
  },
  {
    id: '5',
    location: 'Medium',
    name: 'Change Planning your Change Planning',
    description: 'Change Planning Squared',
    link: 'https://kristiansigston.medium.com/change-plan-your-change-planning-b9dac037715',
    datePublished: 'September 2022',
  },
  {
    id: '4',
    location: 'Medium',
    name: 'Initializing Esbuild-WASM',
    description: 'Initializing esbuild once',
    link: 'https://kristiansigston.medium.com/initializing-esbuild-wasm-84a26b405f12',
    datePublished: 'August 2021',
  },
  {
    id: '3',
    location: 'Medium',
    name: "Why can't I crack passwords like they do in the moveies",
    description: 'Password use discussion',
    link: 'https://kristiansigston.medium.com/why-cant-i-crack-passwords-like-they-do-in-the-movies-2075e630ca1f',
    datePublished: 'April 2021',
  },
  {
    id: '1',
    location: 'Medium',
    name: "What's an Algorithm",
    description: 'Describe an algorithm as part of another blog',
    link: 'https://kristiansigston.medium.com/whats-an-algorithm-6f8046929edc',
    datePublished: 'March 2021',
  },
  {
    id: '2',
    location: 'Medium',
    name: 'What if Roads and Cars were Tried to be Introduced Today',
    description: 'Satirical take on introducing roads and cars today',
    link: 'https://kristiansigston.medium.com/what-if-roads-and-cars-were-introduced-today-5f3f67971c6b',
    datePublished: 'April 2021',
  },
]

export default blogs
