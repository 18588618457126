import React from 'react'
import { blogPosts } from '../../data'
import './blog-posts.scss'

const BlogPost = ({ title, id }) => {
  const renderList = blogPosts.map(
    ({ id, name, description, link, datePublished }) => {
      return (
        <div key={id} className="blog-post">
          <div className="blog-post__heading">{name}</div>
          {/* <div className="blog-post__heading-company">
              <p>{location}</p>
            </div> */}
          <div className="blog-post__heading-date">{datePublished}</div>
          <div className="blog-post_description">
            <a href={link}>
              <p>{description}</p>
            </a>
          </div>
        </div>
      )
    }
  )
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      {renderList}
    </div>
  )
}

export default BlogPost
