const sites = [
  {
    id: 0,
    name: 'To be confirmed',
    description: 'Mindfullness',
    website: '#',
    technologies: [
      'TypeScript',
      'Node',
      'Git',
      'React',
      'SSR, CSR, ISR',
      'NextJs',
      'PostgreSQL',
      'PrismaORM',
      'Digital Ocean',
      'Github Actions',
      'Jest',
      'React Testing Library',
      'MSW',
      'Twilio',
      'SendGrid',
      'Stripe',
      'Cypress',
    ],
    stillMaintaining: 'This site is under construction',
  },
  {
    id: 1,
    name: 'Compare Game Server Hosting',

    description: `<ul>
    <li>Compares game server hosting prices: By gathering data from various hosting providers, the site allows users to easily compare pricing options for different server packages.</li>
    <li>Sorts by multiple criteria: Users can sort hosting providers by price, Trustpilot score, length of time active, total reviews, and CGSH score, ensuring they find the best fit for their needs.</li>
    <li>Filters by country availability: The site enables users to filter hosting providers by the countries they serve, ensuring they find a provider that can accommodate their specific location.</li>
    <li>Filters by game availability: Users can filter providers based on the specific games they support, ensuring the chosen provider caters to their favorite games.</li>
    <li>Filters by platform: The site allows users to filter hosting providers based on the platforms they support, such as PC, console, or mobile, ensuring compatibility with their preferred gaming devices.</li>
    <li>Searches by title name: Users can search for hosting providers by entering the name of a specific game title, making it easy to find options tailored to their preferred gaming experiences.</li>
    <li>Utilizes PostgreSQL and Google Spreadsheets: The site combines the power of a PostgreSQL database with the convenience of Google Spreadsheets for seamless administration, ensuring data integrity and easy management.</li>
    <li>Data verification: Before storing information in the attached database, the site verifies the pulled data, maintaining accuracy and reliability of the information provided.</li>
    <li>Auto-deployment with GitHub Actions: The site is automatically deployed to a hosting provider using GitHub Actions, ensuring efficient updates and consistent performance.</li>
    <li>Scheduled data collection: Data is automatically collected on a predefined schedule, keeping the information up-to-date and relevant for users.</li>
    <li>Full Server-Side Rendering (SSR) enabled: The site features SSR, enhancing performance and improving the overall user experience.</li>
    <li>Pre-loaded data on first hit: When users access the game comparison pages, all data for the site is instantly downloaded and stored on their local machine, ensuring smooth navigation and quick access to essential information.</li>
  </ul>
  `,
    website: 'https://comparegameserverhosting.com/servers/all/all',
    technologies: [
      'JavaScript',
      'Node',
      'Git',
      'React',
      'SSR',
      'SEO',
      'Express',
      'Emotion',
      'PostgreSQL',
      'GoogleSheets',
      'Heroku',
      'BitBucket',
      'Yarn',
      'Jest',
      'A-frame VR (removed)',
    ],
    stillMaintaining: 'This site is Active',
  },
  {
    id: 2,
    name: 'Ginny Bradley website',
    description: 'Website for booking learning courses',
    website: 'https://www.ginnybradley.com',
    technologies: ['On request'],
    stillMaintaining: 'The site is live but not maintained by myself',
  },
  {
    id: 3,
    name: 'CV Holder (This one)',
    description: 'CV holder and spike code for blog posts',
    website: '#',
    technologies: [
      'Create React App',
      'JavaScript',
      'Node',
      'Git',
      'React',
      'NPM',
      'Github',
      'Digital Ocean',
    ],
    stillMaintaining: 'Still Active',
  },
  {
    id: 4,
    name: 'Lightbulber.com',
    description:
      'This idea did not work out too well. It was a great teacher though. This domain has now been commandeered for this site',
    website: '#',
    technologies: [
      'JavaScript',
      'Node',
      'Git',
      'express',
      'NPM',
      'Github',
      'mongoDB',
      'authentication',
      'Digital Ocean',
    ],
    stillMaintaining: 'Active now for this live CV',
  },
  {
    id: 5,
    name: 'Mum of 2 Boys',
    descirption: 'Parenting website',
    website: 'www.mumof2boys.com',
    technologies: ['JavaScript', 'Node', 'Git', 'express', 'NPM', 'Github'],
    stillMaintaining:
      'This site has be moved to wix for ease of user content updating',
  },
]

export default sites
