const singles = [
  {
    title: 'Nation',
    author: 'Terry Pratchett',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780061433016',
  },
  {
    title: 'Fallen Dragon',
    author: 'Peter F Hamilton',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780330480062',
  },
  {
    title: 'Misspent Youth',
    author: 'Peter F Hamilton',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780330480222',
  },
  {
    title: 'Chasm City',
    author: 'Alistair Reynolds',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780441010646',
  },
  {
    title: 'Snow Crash',
    author: 'Neal Stephenson',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780241953181',
  },
  {
    title: 'Diamond Age',
    author: 'Neal Stephenson',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780140270372',
  },
  {
    title: '1984',
    author: 'George Orwell',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9780452284234',
  },
  {
    title: 'Starship Troopers',
    author: 'Robert A Heinlein',
    collection: 'novel',
    type: 'fiction',
    ISBN: '978-0441783588',
  },
  {
    title: 'The Expert Systems Brother',
    author: 'Adrain Tchaikovsky',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9781250197566',
  },
  {
    title: 'The Expert Systems Champion',
    author: 'Adrain Tchaikovsky',
    collection: 'novel',
    type: 'fiction',
    ISBN: '',
  },
  {
    title: 'Walking to Aldebaran',
    author: 'Adrain Tchaikovsky',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9781781087060',
  },
  {
    title: 'Firewalkers',
    author: 'Adrain Tchaikovsky',
    collection: 'novel',
    type: 'fiction',
    ISBN: '9781781088487',
  },
  {
    title: 'Ready Player One',
    author: 'Ernest Cline',
    collection: 'novel',
    type: 'fiction',
    ISBN: '978-0307887443',
  },
]

export default singles
