import React from 'react'
// import { educations } from '../../data'
import './educations.scss'

const Educations = ({ title, data, textOnly, id }) => {
  let renderList = []
  if (textOnly) {
    renderList = data.map(
      ({ title, description, startDate, completedDate }) => {
        return (
          <div key={`${title}${description}`} className="education__item">
            <div className="education__item__title__print">{title}</div>
            <div className="education__item__data__print">
              <div className="education__item__description__print">
                {description}
              </div>
            </div>
          </div>
        )
      }
    )

    //
  } else {
    renderList = data.map(
      ({ title, description, link = '#', image, institution, date }) => {
        const hasImage = image ? <img src={image} alt={institution} /> : null
        return (
          <div key={`${title}${description}`} className="educations">
            <a className="educations__institution-link" href={link}>
              <div className="educations__image-container">{hasImage}</div>
              <div className="educations__title">{title}</div>
            </a>
            <div className="education-data">
              <div className="educations__description">{description}</div>
              <div className="educations__date">{date}</div>
            </div>
          </div>
        )
      }
    )
  }
  return (
    <div className="section" id={id}>
      <h2 className="section__title">{title}</h2>
      <div className="educations-container-print">{renderList}</div>
    </div>
  )
}
export default Educations
