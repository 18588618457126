import React from 'react'
import { technologies, languages } from '../../data'
import './tech.scss'

const colors = { 0: 'lightblue', 1: 'orange', 2: 'lightgreen' }

const Technology = ({ title, id }) => {
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      <div className="tech-and-lang">
        <h3>Languages</h3>
        <div className="languages">
          {languages.map((language, index) => {
            const color = index % 3
            const style = { backgroundColor: colors[color] }
            return (
              <span style={style} key={language} className="language-item">
                {language}
              </span>
            )
          })}
        </div>
        <h3>Technologies</h3>
        <div className="technologies">
          {technologies.map((technology, index) => {
            const color = index % 3
            const style = { backgroundColor: colors[color] }
            return (
              <span style={style} key={technology} className="tech-item">
                {technology}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Technology
