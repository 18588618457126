import React from 'react'
import './books.scss'
import Book from '../book'
import Series from '../series'

const Books = ({ title, books, id, extraClass = '', }) => {
  const renderList = books.map((book, index) => {
    if (book.collection === 'series') {
      return (
        <div
          key={book.title}
          className={
            index % 2 === 1 ? 'book-series odd-one' : 'book-series even-one'
          }
        >
          <h2 style={{ padding: 0, margin: 0 }}>{book.title}</h2>
          <h3 style={{ padding: 0, margin: 0 }}>{book.author}</h3>
          <div
            key={book.title}
            className="book-series"
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <ul>
              <Series
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                getCovers={false}
                series={book.books}
              />
            </ul>
          </div>
        </div>
      )
    }
    return (
      <Book
        getCovers={false}
        className="book-container"
        key={book.title}
        book={book}
      />
    )
  })
  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      <div className={`books-container ${extraClass}`}>{renderList}</div>
      <h3>
        Covers retrieved from{' '}
        <a href="https://openlibrary.org/dev/docs/api/covers">
          https://openlibrary.org/dev/docs/api/covers
        </a>
      </h3>
    </div>
  )
}

export default Books
