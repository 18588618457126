const dbExperiences = [
  {
    jobDescription: 'Accountant / Estate Planner',
    order: '2',
    description: [
      'Key Skills and Experience:',
      'Leadership and Team Development',
      'Staff Training and Mentorship',
      'System Implementation and Process Innovation',
      'Technical Accounting Expertise',
      'Interpersonal Communication and Client Relations',
      'Auditing and Compliance',
      'Advanced Excel Proficiency',
      'Professional Experience:',
      'Digital System Implementation: Spearheaded the transition to a digital system for tax returns, accounts, and probate case documentation, significantly improving operational efficiency and reducing manual errors.',
      'Team Management and Operations Oversight: Managed office operations, supervising a team of staff, and ensuring optimal allocation of work to meet client needs and deadlines. Successfully maintained high standards of service delivery.',
      'Audit Leadership: Led audit teams as a senior member, managing workloads based on team capabilities. Worked with companies ranging from sole traders with £50k turnover to groups of companies with up to £190m turnover, ensuring compliance and accuracy in financial reporting.',
      'Interim Finance Director: Provided strategic financial guidance to directors of a £16m turnover firm, part of a larger foreign group. Assisted in financial analysis and decision-making, enhancing the company’s financial health and strategic planning.',
      'Custom Financial Forecasting Projects: Developed detailed forecasting models and spreadsheets tailored to client needs, including complex group companies in the construction industry with turnovers up to £190m. These tools enabled clients to make informed business decisions.',
      'Training and Development: Conducted comprehensive training programs, including inductions for new staff across all levels, ensuring a deep understanding of accounting practices and procedures. Enhanced team capabilities and knowledge retention.',
      'Wills and Probate Department Oversight: Led all aspects of the wills department, from lead generation and client meetings to will creation and billing. Delivered empathetic and efficient probate services, ensuring client satisfaction.',
      'Software and Process Innovation: Actively participated in software user group meetings, driving process improvements and advocating for innovative solutions to enhance operational workflows.',
      'Statutory Accounts and Tax Returns: Prepared statutory accounts, CIS, and VAT returns, ensuring accurate reconciliation and analysis of balance sheet and profit and loss statements. Provided advisory services based on financial insights.',
      'Assurance and Corporate Finance: Assisted in preparing limited assurance and assurance reports for grants and corporate finance tasks, ensuring compliance and accuracy.',
      'Accountancy Software Proficiency: Extensive experience with CCH/IRIS, Sage, Xero, QuickBooks, RedSky, Zoho, and Solar Accounts, optimizing accounting processes and ensuring efficient use of technology.',
      'Presentation Design and Delivery: Designed and delivered presentations for internal and external training workshops, utilizing Word, PowerPoint, and Publisher to effectively communicate key concepts and training materials.',
    ],
    dateFrom: 'January 2015 – Current',
    dateTo: 'Current',
    location: 'Chatham, Kent',
    companyName: 'Perrys Chartered Accountants',
  },
  {
    jobDescription: 'Trainee Financial Accountant',
    order: '1',
    description: [
      'Reconciling and maintaining numerous control accounts along with the fixed asset register',
      'Liaising with various levels of management regarding budgets and actual capital expenditure',
      'Assisting with statutory accounts and board reports',
    ],
    dateFrom: 'October 2013',
    dateTo: 'December 2014',
    location: 'Gillingham, Kent',
    companyName: 'Medway Maritime Hospital',
  },
  {
    jobDescription: 'Administration Assistant / Driver',
    order: '0',
    description: [
      'Working as part of a very busy team and supporting the office managers',
      'Responsible for day-to-day tasks and delivering notes to and from different sites across Kent and East Sussex',
    ],
    dateFrom: 'October 2012',
    dateTo: 'October 2013',
    location: 'Gillingham, Kent',
    companyName: 'Medway Maritime Hospital',
  }
];


export default dbExperiences
