import React, { useState } from 'react'
import './courses-style.scss'
import { courses } from '../../data'

const Courses = ({ id, title }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const renderList = courses
    .filter((course) =>
      course.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((course) => {
      const {
        title,
        courseLink,
        image,
        description,
        institution,
        startDate,
        completedDate,
        completed,
      } = course
      const date = completed
        ? `Started ${startDate} and completed ${completedDate}`
        : `${startDate} - Present`

      return (
        <div
          key={title}
          className="course__card"
          style={{ backgroundColor: completed ? 'lightgreen' : 'orange' }}
        >
          <div className="course__card__name">
            <a className="course__card__link" href={courseLink}>
              {title}
            </a>
          </div>
          <div className="course__card__description">
            <p>{description}</p>
            <p>{date}</p>
          </div>
          <a className="course__card__link" href={courseLink}>
            <div className="course__card__image__container">
              <img
                className="course__card__image__container__image"
                src={image}
                alt={`${title} logo`}
              />
            </div>
          </a>
          <p className="course__card__institution">{institution}</p>
        </div>
      )
    })

  return (
    <div id={id} className="section">
      <h2 className="section__title">{title}</h2>
      <input
        type="text"
        className="course__filter"
        placeholder="Filter courses by title..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="course">{renderList}</div>
    </div>
  )
}

export default Courses
