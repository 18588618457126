import React, { useRef, useState, useEffect } from 'react'
import '../async-group.scss'
import AnimItem from '../async-item'

export const AsyncPromiseAnyGroup = () => {
  const [firstAnimsResult, setFirstAnimsResult] = useState('Not Started')
  const [anim1TimeoutId, setAnim1TimeoutId] = useState(null)

  const [anim1Status, setAnim1Status] = useState('Not Started')
  const [anim2Status, setAnim2Status] = useState('Not Started')
  const [anim3Status, setAnim3Status] = useState('Not Started')

  const anim1 = useRef()
  const anim2 = useRef()
  const anim3 = useRef()

  const getStatusColor = (status) => {
    switch (status) {
      case 'Not Started':
        return 'white'
      case 'Pending':
        return 'yellow'
      case 'Fulfilled':
        return 'green'
      case 'Rejected':
        return 'red'
      default:
        return 'white'
    }
  }

  const resetPromisesAnyAnim = () => {
    setFirstAnimsResult('Not Started')
    setAnim1Status('Not Started')
    setAnim2Status('Not Started')
    setAnim3Status('Not Started')
    anim1.current.classList.remove('ani-async-1s')
    anim2.current.classList.remove('ani-async-3s')
    anim3.current.classList.remove('ani-async-2s')
  }

  const startPromiseAnyAnim = async () => {
    setFirstAnimsResult('Pending')
    setAnim1Status('Pending')
    setAnim2Status('Pending')
    setAnim3Status('Pending')

    anim1.current.classList.add('ani-async-1s')
    anim2.current.classList.add('ani-async-3s')
    anim3.current.classList.add('ani-async-2s')

    anim1.current.onanimationend = () => {
      setAnim1Status('Fulfilled')
    }

    anim2.current.onanimationend = () => {
      setAnim2Status('Fulfilled')
    }

    anim3.current.onanimationend = () => {
      setAnim3Status('Fulfilled')
    }

    const timeoutId = setTimeout(() => {
      // Cancel anim2 animation to simulate a failure
      anim1.current.getAnimations().forEach((animation) => animation.cancel())
      setAnim1Status('Rejected')
    }, 500)

    setAnim1TimeoutId(timeoutId) // Save the timeout ID for cleanup

    try {
      const result = await Promise.any([
        ...(anim1.current && anim1.current.getAnimations()
          ? anim1.current.getAnimations().map((animation) => animation.finished)
          : []),
        ...(anim2.current && anim2.current.getAnimations()
          ? anim2.current.getAnimations().map((animation) => animation.finished)
          : []),
        ...(anim3.current && anim3.current.getAnimations()
          ? anim3.current.getAnimations().map((animation) => animation.finished)
          : []),
      ])

      // Check the overall status of the result and set the firstAnimsResult accordingly
      setFirstAnimsResult('Fulfilled')
    } catch (error) {
      setFirstAnimsResult('Rejected')
    }
  }

  return (
    <React.Fragment>
      <h3>Promise.any - One Fails</h3>
      <p>Settles as soon as any one of the input promises fulfills</p>
      <p>
        With Promise.any, the returned promise is fulfilled as soon as any one
        of the input promises fulfills. If all input promises reject, the
        overall Promise.any is considered a failure and rejects with an
        AggregateError.
      </p>
      <button className="animation-ui-button"
        onClick={
          firstAnimsResult === 'Not Started'
            ? startPromiseAnyAnim
            : resetPromisesAnyAnim
        }
      >
        {firstAnimsResult === 'Not Started' ? 'Start' : 'Reset'}
      </button>
      <div className="example">
        <div className="test">
          <AnimItem
            backgroundColor={getStatusColor(anim1Status)}
            ref={anim1}
            status={anim1Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim2Status)}
            ref={anim2}
            status={anim2Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim3Status)}
            ref={anim3}
            status={anim3Status}
          />
        </div>
        <div
          style={{ backgroundColor: getStatusColor(firstAnimsResult) }}
          className="result"
        >
          <div className="result-text">
            <span>Promise.any Result:</span>
            <span>{firstAnimsResult}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AsyncPromiseAnyGroup
