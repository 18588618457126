export { default as courses } from './courses'
export { default as projects } from './projects'
export { default as blogPosts } from './blog_posts'
export { default as experiences } from './experiences'
export { default as books } from './books'
export { default as podcasts } from './podcasts'

export const technologies = [
  'Bitbucket',
  'CSS-in-JS',
  'DialogFlow',
  'Emotion',
  'Express',
  'Flexbox - CSS',
  'Git',
  'Github',
  'Grid - CSS',
  'Heroku',
  'JSON',
  'MongoDB',
  'NextJS',
  'Node',
  'PostgreSQL',
  'React',
  'RegExp',
  'REST',
  'SCSS',
  'SEO',
  'SSR',
  'Twilio',
]

export const languages = [
  'TypeScript',
  'JavaScript',
  'NodeJs',
  'SCSS',
  'CSS',
  'HTML',
]

export const interests = [
  'Piano - Grade 1',
  'Classical Guitar',
  'Electronics',
  'Machine Learning',
  'Martial Arts',
  'Running',
  'Science Fiction',
  'Security',
  'Video Games',
]

export const socialLinks = [
  // { name: 'Twitter', url: 'https://twitter.com/contutious' },
  { name: 'GitHub', url: 'https://github.com/kristiansigston' },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/kristian-sigston/',
  },
  { name: 'Medium', url: 'https://kristiansigston.medium.com/' },
]
