import Book from '../book'

const Series = ({ series }) => {
  const seriesList = series.map((seriesBook) => {
    return (
      <Book
        getSeries={false}
        key={`${seriesBook.title}${series.title}`}
        book={seriesBook}
      />
    )
  })

  return seriesList
}

export default Series
